<template>
  <div v-loading="loading" class="order-detail-container">
    <div class="buttonbc">
      <el-button @click="() => {
    $router.go(-1);
  }
    " size="small" class="back_btn">
        <img src="../../../assets/back.png" alt="" />
      </el-button>
    </div>
    <div class="cont">
      <br />
      <br />
      <h4>基本信息</h4>
      <table class="table-cont">
        <tbody>
          <tr>
            <td class="details-div-span">
              交易状态：
              <span class="detail-span1">
                {{
    orderDetail.order_status_text
      ? orderDetail.order_status_text
      : orderDetail.trade_status_text
        ? orderDetail.trade_status_text
        : ""
  }}
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style="color: red">
                {{
      orderDetail.hangup_status === 1
        ? "(店铺挂起)"
        : orderDetail.hangup_status === 2
          ? "(企业挂起)"
          : ""
    }}
              </span>
            </td>
          </tr>
          <tr>
            <td>下单人信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人姓名：{{ orderDetail.member_name }}</td>
            <td>下单人手机号：{{ orderDetail.mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人账号：{{ orderDetail.login_account || "" }}</td>
          </tr>
          <tr>
            <td>订单信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>商城下单时间：{{ orderDetail.create_time | unixToDate }}</td>
            <td>商城支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>商城支付方式：站点账户余额</td>
            <td>
              商城支付金额：{{
    orderDetail.enterprise_total_price | unitPrice("￥")
  }}
              (商品: {{ orderDetail.enterprise_goods_price | unitPrice("¥") }} +
              企业运费:
              {{ orderDetail.enterprise_freight_price | unitPrice("¥") }})
            </td>
          </tr>
          <!-- 企业 收货信息 -->
          <tr>
            <td>收货人信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>收货人：{{ orderDetail.consignee_name }}</td>
            <td>收货人手机号：{{ orderDetail.consignee_mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>
              收货地址：{{ orderDetail.consignee_province
              }}{{ orderDetail.consignee_city }}
              {{ orderDetail.consignee_county }}
              {{ orderDetail.consignee_town || ""
              }}{{ orderDetail.consignee_address }}
            </td>
          </tr>
          <tr>
            <td>备注信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>{{ order_remark }}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <el-tabs v-model="propCurrentTab" type="card" @tab-click="GET_OrderDetail(propCurrentTab)">
        <el-tab-pane label="商品及物流信息" name="0">
          <h4>商品信息</h4>
          <!-- 站点、供应商 -->
          <template>
            <el-table :data="productList" :header-cell-style="{ background: '#cccccc' }" :expand-row-keys="rowKeysList"
              row-key="sku_sn">
              <el-table-column prop="goods_image" label="商品详情" width="280">
                <template slot-scope="scope">
                  <div style="
                      display: flex;
                      flex-direction: row;
                      flex-wrap: nowrap;
                    ">
                    <img :src="scope.row.goods_image" class="goods-image" style="margin-right: 15px" alt="" />
                    <el-tooltip class="item" effect="light" :content="scope.row.name" placement="top">
                      <el-button style="
                          max-width: 19vw;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        " class="shop-name" type="text">{{ scope.row.name }}
                      </el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量"></el-table-column>
              <el-table-column label="会员价" v-if="parentLogin === 1">
                <template slot-scope="scope">
                  <span>{{ scope.row.original_price | unitPrice("￥") }}</span>
                </template>
              </el-table-column>
              <!-- 站点展示分销价，供应商不展示分销价 -->
              <el-table-column label="分销价">
                <template slot-scope="scope">
                  <span>{{
    scope.row.enterprise_purchase_price | unitPrice("￥")
  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="shop_total_price" label="物流状态">
                <template slot-scope="{ row }">
                  <span v-if="row.logistics_status_text">{{
    row.logistics_status_text
  }}</span>
                  <span v-else>{{
      !row.state
        ? "未发货"
        : row.state === 2
          ? "已发货"
          : "已收货"
    }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="shop_total_price" label="维权状态">
                <template slot-scope="scope">
                  {{ fService_status(scope.row.service_status) }}
                </template>
              </el-table-column>
              <el-table-column label="京东单号" width="130">
                <template slot-scope="scope">
                  <span v-if="scope.row.ext_order_id">{{
    scope.row.ext_order_id
  }}</span>
                  <span v-else>——</span>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="{ row }">
                  <el-button type="button" size="mini" v-if="row.service_status == 'NOT_APPLY' && row.state !== 0"
                    @click="haddlerApply(row)">申请售后
                  </el-button>
                  <el-button type="button" size="mini" v-if="row.service_status !== 'NOT_APPLY' && row.state !== 0"
                    @click="haddlerInfo(row)">查看售后
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <h4 v-if="expressInfo.length !== 0">包裹信息</h4>
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane v-for="(item, index) in expressInfo" :key="index" :label="item.message" :name="item.index">
              <p style="background-color: #6666; height: 30px; line-height: 30px">
                <span>快递公司: {{ item.deliver_name }}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>物流单号: {{ item.delivery_no }}</span>
              </p>
              <div style="display: flex">
                <div v-for="(i, index) in item.images" :key="index"
                  style="width: 100px; height: 100px; margin-right: 20px">
                  <img :src="i" style="width: 100%; height: 100%" />
                </div>
              </div>
              <div class="logistics-info" v-if="item.data.code === 200">
                <p v-if="!item.data.data[0].express.data" style="display: block; line-height: 20px">
                  {{
    item.data.data[0].express.message ||
    item.data.data[0].express
  }}
                </p>
                <ul v-else>
                  <li v-for="(ship, index) in item.data.data[0].express.data" :key="index" class="ship-item">
                    <i class="ship-dot"></i>
                    <div class="ship-msg">
                      <p class="ship-msg-text">{{ ship.context }}</p>
                      <p class="ship-msg-time">{{ ship.time }}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="logistics-info" v-else>
                <p v-if="!item.data.data.orderTrack" style="display: block; line-height: 20px">
                  {{ item.data.data.message }}
                </p>
                <ul v-else>
                  <li v-for="(ship, index) in item.data.data.orderTrack" :key="index" class="ship-item">
                    <i class="ship-dot"></i>
                    <div class="ship-msg">
                      <p class="ship-msg-text">{{ ship.content }}</p>
                      <p class="ship-msg-time">{{ ship.msgTime }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--申请售后-->
    <Applyforaftersales @cancel="cancel" :sellerId="sellerId" :isJDGoods="isJDGoods" title="申请售后" :formdata="txInfoFrom"
      @refresh="refresh" :isShow="dialogApply">
    </Applyforaftersales>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API_order from "@/api/order";
import * as API_Goods from "@/api/goods";
import * as API_logistics from "@/api/expressCompany";
import * as Foundation from "@/../ui-utils/Foundation";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import Applyforaftersales from "@/components/Applyforaftersales";
const routeName = {
  "ai-combo-order-list": "aiComboOrderList",
  "combo-order-list": "comboOrderList",
  "shop-order-list": "shopOrderList",
};

export default {
  name: "orderDetail",
  components: { EnTableLayout, Applyforaftersales },
  computed: {
    ...mapGetters(["shopInfo"]),
  },
  data () {
    return {
      isJDGoods: false,
      sellerId: 0,
      propCurrentTab: "0",
      order_remark: "", //订单备注
      // 订单备注列表
      remarkParams: {
        platform: "",
        sn: "",
        start: "",
        end: "",
        time_range: [],
        operator: "",
        page: 1,
        size: 10,
      },
      platformOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "供应商",
        },
        {
          value: "1",
          label: "平台",
        },
      ],
      remarkList: [],
      rowKeysList: [],
      /** 列表loading状态 */
      loading: false,
      dialogApply: false,
      txInfoFrom: {
        order_sn: "",
        sku_id: 0,
      },
      /** 订单详情数据 */
      orderDetail: {},
      productList: [],
      /** 物流信息 */
      logisticsData: [],
      /* 商品信息数组 */
      tableData: {},
      /*  快递公司ID和名称 */
      selectValue: "",
      /*  订单编号 */
      sn: "",
      /* 物流单号 */
      ship_no: "",
      route: "",
      expressInfo: [],
      activeName: "1",
      dialogVisible: false,
      /*订单商品数据*/
      sku_list: [],
      selectList: [],
      formInline: {
        logi_id: "",
      },
      parentLogin: 1,
      type: "",
    };
  },
  filters: {
    paymentTypeFilter (val) {
      return val === "ONLINE" ? "在线支付" : "线下支付";
    },
  },
  beforeRouteUpdate (to, from, next) {
    this.sn = to.params.sn;
    this.GET_OrderDetail(this.propCurrentTab);
    next();
  },
  mounted () {
    this.$route.meta.activeNames = [
      ...this.$route.meta.activeNames,
      routeName[this.$route.path.split("/")[3]],
    ].filter((item) => item);
    this.sn = this.$route.params.sn;
    this.type = this.$route.query.type;
    sessionStorage.setItem("orderListActiveName", this.type);
    this.GET_OrderDetail(this.propCurrentTab);
    this.route = this.$route.path.split("/")[2];
    this.getLogisticsCompanies();
    API_Goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
    });
  },
  activated () {
    this.sn = this.$route.params.sn;
    this.GET_OrderDetail(this.propCurrentTab);
  },
  methods: {
    /** 获取物流公司信息列表 */
    getLogisticsCompanies () {
      API_logistics.getExpressCompanyList({}).then((res) => {
        this.logisticsData = res;
      });
    },
    handleClose () {
      this.dialogVisible = false;
    },

    checkboxT (row, index) {
      return row.state !== 3;
    },
    submitFhing () {
      for (let i = 0; i < this.selectList.length; i++) {
        let index = i;
        let item = this.selectList[i];
        if (!item.logi_id) {
          this.$message.error("请选择物流公司");
          return false;
        }
        if (item.delivery_list) {
          for (let j = 0; j < item.delivery_list.length; j++) {
            let item1 = item.delivery_list[j];
            if (!item1.delivery_no) {
              this.$message.error("请输入物流单号");
              return false;
            }
          }
        }
      }
      this.$confirm("确认发货?", "提示", { type: "warning" }).then(() => {
        let fhInfo = {
          order_sn: this.sn,
          delivery_dolist: [],
        };
        this.selectList.forEach((item, index) => {
          item.delivery_list.forEach((item1, index1) => {
            fhInfo.delivery_dolist.push({
              logi_id: item.logi_id,
              logi_name: item.logi_name,
              order_sn: this.sn,
              sku_id: item.product_id,
              goods_id: item.goods_id,
              goods_name: item.name,
              goods_sn: item.sku_sn,
              ship_num: item.num,
              delivery_no: item1.delivery_no,
            });
          });
        });
        // console.log(fhInfo)
        API_order.deliveryNew(fhInfo).then(() => {
          this.$message.success("发货成功");
          this.GET_OrderDetail(this.propCurrentTab);
          this.dialogVisible = false;
        });
      });
    },
    filterLogisticsData (row) {
      this.logisticsData.forEach((res) => {
        if (res.logi_id == row.logi_id) {
          row.logi_name = res.name;
        }
      });
    },

    fService_status (status) {
      switch (status) {
        case "NOT_APPLY":
          return "未申请";
        case "APPLY":
          return "已申请";
        case "PASS":
          return "审核通过";
        case "REFUSE":
          return "审核未通过";
        case "EXPIRED":
          return "已失效或不允许申请售后";
        case "REFUNDFAIL":
          return "退款失败";
        case "COMPLETED":
          return "完成";
        case "WAIT_SEND_BACK":
          return "待寄回";
        case "SEND_BACK":
          return "买家寄回";
        case "SELLER_SHIP":
          return "卖家发货";
        case "REFUSE_SHIP":
          return "卖家拒绝发货";
        default:
          return "";
      }
    },
    /** 获取订单详情信息 */
    GET_OrderDetail () {
      this.loading = true;
      API_order.getTradeInfo(this.sn).then((res) => {
        this.backFunc(res);
      });
    },
    async backFunc (res1) {
      this.loading = false;
      // 订单信息
      this.orderDetail = res1;
      this.orderDetail["payment_method_name"] = this.orderDetail
        .payment_method_name
        ? this.orderDetail.payment_method_name.replace(/,/g, "+")
        : "";
      let res = await API_order.getOrderItemsList(this.sn);
      let list = [];
      // 商城支付金额
      let enterprise_goods_price = 0;
      this.orderDetail.order.forEach((item) => {
        item.sku_list.forEach((i) => {
          i.ext_order_id = item.ext_order_id;
          list.push(i);
          enterprise_goods_price += i.enterprise_purchase_price * i.num;
        });
      });
      this.orderDetail["enterprise_goods_price"] = enterprise_goods_price;
      this.productList = list;
      this.order_remark = this.orderDetail.order[0].remark;
      this.productList.forEach((item) => {
        if (!item.state) {
          item.state = 0;
        }
      });
      let params = {};
      params["order_sn"] = this.sn;
      params["sku_sn"] = this.productList[0]["sku_sn"];

      if (this.$route.query.type === "2" || this.shopInfo.shop_type === 2) {
        await API_order.getLogisticsOrder(params).then((res) => {
          this.expressInfo = res;
          this.expressInfo.forEach((item, index) => {
            item["message"] = "包裹" + String(index + 1);
            item["index"] = String(index + 1);
            if (item.data.code === 1) {
              item.data.data.orderTrack.reverse();
            }
          });
        });
      } else {
        await API_order.getLogistics(params).then((res) => {
          this.expressInfo = res;

          this.expressInfo.forEach((item, index) => {
            item["message"] = "包裹" + String(index + 1);
            item["index"] = String(index + 1);
            console.log(item.data.code);
            if (item.data.code === 1) {
              item.data.data.orderTrack.reverse();
            }
          });
          console.log(this.expressInfo, 'expressInfo');
        });
      }
      // ALL:所有订单,WAIT_PAY:待付款,WAIT_SHIP:待发货,WAIT_ROG:待收货,","CANCELLED:已取消,COMPLETE:已完成,WAIT_COMMENT:待评论,REFUND:售后中
    },
    handleDateChange (val) {
      if (val) {
        this.remarkParams.start = parseInt(val[0].getTime() / 1000);
        this.remarkParams.end = parseInt(val[1].getTime() / 1000 + 86400);
      } else {
        this.remarkParams.start = "";
        this.remarkParams.end = "";
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.remarkParams.size = size;
      this.GET_OrderDetail(this.propCurrentTab);
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.remarkParams.page = page;
      this.GET_OrderDetail(this.propCurrentTab);
    },

    //跳转售后详情页
    haddlerInfo (row) {
      this.$router.push({
        path: `/order/refund-detail/${row.refund_sn}`,
      });
    },
    //打开申请售后弹窗
    haddlerApply (sku) {
      const order = this.orderDetail.order.filter((_order) => _order.sku_list.filter((_sku) => _sku.sku_id === sku.sku_id).length > 0)[0];
      this.isJDGoods = this.orderDetail.jd_order_id ? true : false;
      console.log(sku);
      this.sellerId = sku.seller_id;
      this.txInfoFrom = {
        order_sn: order.sn,
        sku_id: sku.sku_id,
        refund_type: '',//售后类型
        refund_reason: '',//售后原因
        refund_desc: '',//问题描述
        refund_img: '',//图片凭证
        refund_ship_type: 1,//退货方式
        //上门取件地址
        pickup_name: this.orderDetail.consignee_name, //上门取件姓名
        pickup_mobile: this.orderDetail.consignee_mobile, //上门取件手机号
        pickup_province_id: this.orderDetail.consignee_province_id, //上门取件省id
        pickup_province: this.orderDetail.consignee_province, //上门取件省
        pickup_city_id: this.orderDetail.consignee_city_id, //上门取件市id
        pickup_city: this.orderDetail.consignee_city, //上门取件市
        pickup_county_id: this.orderDetail.consignee_county_id, //上门取件区id
        pickup_county: this.orderDetail.consignee_county, //上门取件区
        pickup_town_id: this.orderDetail.consignee_town_id || "", //上门取件镇id
        pickup_town: this.orderDetail.consignee_town || "", //上门取件镇
        pickup_address: this.orderDetail.consignee_address, //上门取件详细地址
        exchange_shipping_is_pickup: 1,//与上门取件地址保持一致
        //换货收货地址
        exchange_shipping_name: this.orderDetail.consignee_name, //收货人姓名
        exchange_shipping_mobile: this.orderDetail.consignee_mobile, //收货手机号
        exchange_shipping_province_id: this.orderDetail.consignee_province_id, //收货省id
        exchange_shipping_province: this.orderDetail.consignee_province, //收货省
        exchange_shipping_city_id: this.orderDetail.consignee_city_id, //收货市id
        exchange_shipping_city: this.orderDetail.consignee_city, //收货市
        exchange_shipping_county_id: this.orderDetail.consignee_county_id, //收货区id
        exchange_shipping_county: this.orderDetail.consignee_county, //收货区
        exchange_shipping_town_id: this.orderDetail.consignee_town_id || "", //收货镇id
        exchange_shipping_town: this.orderDetail.consignee_town || "", //收货镇
        exchange_shipping_address: this.orderDetail.consignee_address, //收货详细地址
      };
      console.log(this.txInfoFrom);
      this.dialogApply = true;
    },
    //关闭申请售后弹窗
    cancel () {
      this.dialogApply = false;
    },
    //售后申请后关闭弹窗刷新页面
    refresh () {
      this.GET_OrderDetail(this.propCurrentTab);
      this.dialogApply = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .wlcz {
  list-style: none;
  margin-bottom: 0;
  padding-left: 10px;
}

/deep/ .cell {
  text-align: center;
}

.logistics-info {
  background-color: #fff;
  padding: 10px 10px 50px 10px;

  // text-align: center;
  .ship-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      .ship-dot {
        background-color: #ec3a33;
      }

      p {
        color: #ec3a33;
      }
    }

    &:not(:last-child)::after {
      content: " ";
      position: absolute;
      z-index: 1;
      pointer-events: none;
      background-color: #e5e5e5;
      width: 1px;
      top: 37px;
      left: 10px;
      bottom: -20px;
    }
  }

  .ship-dot {
    position: relative;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    color: #666;
    margin: 24px 15px 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  .ship-msg {
    flex: 1;
    padding: 15px 0;
  }

  .ship-msg-text {
    color: #333;
    font-size: 14px;
    line-height: 18px;
  }

  .ship-msg-time {
    font-size: 12px;
    color: #999;
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont.order-table td {
  border-top: 0;
}

.table-cont>tbody>tr>td {
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}

.m-l-md {
  margin-left: 20px !important;
}

.order-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.buttonbc {
  float: right;
  height: 40px;

  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1a43a9;
    border-radius: 50%;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

.cont {
  /* width: 1400px; */
  /* margin-left: 80px; */
  margin-left: 30px;
  padding-bottom: 20px;
}

/deep/ .is-leaf {
  /* border: 1px solid #000000 !important; */
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table__row td {
  /* border: 1px solid #000000 !important; */
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table--border {
  /* border: 1px solid #000000 !important; */
}

.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}

/deep/ .el-table__row {
  border-collapse: separate;
  border-bottom: 1px solid #ebeef5;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}

/deep/ .el-scrollbar {
  height: 560px;
}
</style>
